
<template>
    <div class="container">
        <div class="header">
            <div class="search">
                <el-input placeholder="Утасны дугаар" type="text" v-model="phone" style="margin-right:5px"></el-input>
                <el-input placeholder="Захиалгын дугаар" type="text" v-model="order_number" style="margin-right:5px"></el-input>
                <el-col :span="15">
                    <el-date-picker v-model="start_date" type="date" placeholder="Эхлэх огноо" style="margin-right: 10px">
                    </el-date-picker>
                </el-col>
                <el-col :span="15">
                    <el-date-picker v-model="end_date" type="date" placeholder="Дуусах огноо" style="margin-right: 10px" >
                    </el-date-picker>
                </el-col>
                <el-select v-model="selectedChannel" placeholder="Сувгаа сонгоно уу" class="custom-size" style="width: 350px; margin-right: 10px;">
                    <el-option
                        v-for="item in channels"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                    </el-select>
                <el-button type="success" @click="getOrderFilter">
                    Хайх
                </el-button>
            </div>
        </div>
        <div class="content">
            <el-table v-loading="isLoading" size="mini" :data="list" stripe style="width: 100%">
                <el-table-column prop="phone" label="Утасны дугаар"></el-table-column>
                <el-table-column prop="order_number" label="Захиалгын дугаар"></el-table-column>
                <el-table-column prop="branch" label="Салбар"></el-table-column>
                <el-table-column prop="address" label="Хаяг"></el-table-column>
                <el-table-column prop="is_paid_text" label="Төлбөр"></el-table-column>
                <el-table-column prop="order_status_text" label="Төлбөрийн хэлбэр"></el-table-column>
                <el-table-column label="" width="200">
                    <template slot-scope="scope">
                        <div class="action">
                            <el-button type="success" size="mini" @click="handleShow(scope.row.id)">Харах</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="pagination" background :current-page="pagination.pageNumber"
                @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size="pagination.pageSize"
                :page-sizes="[10, 20, 50, 100]" :total="total" layout="total, sizes, prev, pager, next, jumper" />
        </div>

        <el-dialog title="Төлбөрийн дэлгэрэнгүй харах" :visible.sync="modalOpen" width="640px">
            <el-form ref="form" :model="form" label-width="150px">
                <div class="form-wrap">
                    <label>Утасны дугаар:</label>
                    <p>{{order.phone}}</p>
                </div>             
                <div class="form-wrap">
                    <label>Захиалгын дугаар:</label>
                    <p>{{order.order_number}}</p>
                </div>
                <div class="form-wrap">
                    <label>Захиалгын суваг:</label>
                    <p>{{order.channel}}</p>
                </div>
                <div class="form-wrap">
                    <label>Нийт дүн:</label>
                    <p>{{order.pay_total}}</p>
                </div>
                <div class="form-wrap">
                    <label>Захиалга хүлээн авсан:</label>
                    <p>{{ formatDateTime(order.received_at) }}</p>
                </div>
                <div class="form-wrap">
                    <label>Салбар</label>
                    <p>{{order.outlet_name}}</p>
                </div>
                <div class="form-wrap">
                    <label>Төлбөрийн хэлбэр</label>
                    <p>{{order.pay_type_name}}</p>
                </div>
                <div v-if="this.invoice">
                    <h3 style="width: 200px; display: block; padding:7px 0; margin: 0 auto;">Төлбөрийн дэлгэрэнгүй</h3>
                    <div class="form-wrap">
                        <label>Төлбөрийн хүсэлт үүсгэсэн: </label>
                        <p>{{ formatDateTime(invoice.createdAt) }}</p>
                    </div>
                    <div class="form-wrap">
                        <label>Төлбөрийн дүн: </label>
                        <p>{{ invoice.amount }}</p>
                    </div>
                    <div class="form-wrap">
                        <label>Төлөгдсөн эсэх: </label>
                        <p>{{ invoice.isPaid == 1 ? 'Төлөгдсөн': 'Үгүй' }}</p>
                    </div>
                    <div class="form-wrap">
                        <label>Төлбөрийн статус: </label>
                        <p>{{ invoice.isPaid == 1 ? 'Төлөгдсөн': 'Үгүй' }}</p>
                    </div>
                    <div class="form-wrap">
                        <label>Мэдээлэл: </label>
                        <p>{{ invoice.errorText }}, {{ invoice.description}}</p>
                    </div>
                </div>

                <el-form-item>
                    <el-button type="primary" @click="() => modalOpen = false">Хаах</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

    </div>
</template>

<script>
import managerServices from '@/services/managerServices.js'
import dateFormat from 'dateformat'
export default {
    // created () {
    //     this.getPaymentList()
    // },
    data () {
        return {
            selectedChannel: '',
            channels: [{
                value: 'all',
                label: 'Бүгд'
                }, {
                value: 'new CC',
                label: 'new CC'
                }, {
                value: 'web',
                label: 'web'
                }, {
                value: 'chatbot',
                label: 'chatbot'
            }],
            list: [],
            order: {},
            invoice:{},
            isLoading: false,
            pagination: {
                pageNumber: 1,
                pageSize: 10,
            },
            pageLen: 10,
            total: 0,
            start_date: '',
            end_date: '',
            phone: '',
            order_number: '',
            form: {
                phone: '',
                reason: '',
            },
            errors: {
                order_number: "",
                phone: "",
                channel: "",
            },
            modalOpen: false,
            rules: {
                phone: [
                    { required: true, message: 'Утасны дугаар оруулна уу', trigger: 'change' }
                ],
                reason: [
                    { required: true, message: 'Шалтгаан оруулна уу', trigger: 'change' }
                ],
            },
        }
    },
    methods: {
        formatDateTime(dateString) {
            if (!dateString) {
                return "Invalid date";
            }
            return dateString.replace("T", " ").replace("Z", "");
        },
        validateOrderNumber () {
            if (this.order_number === '') {
                this.errors.order_number = "Захиалгын дугаар оруулна уу'";
            }else {
                this.errors.order_number = "";
            }
        },
        validatePhone () {
            if (this.phone === '') {
                this.errors.phone = "Утасны дугаар оруулна уу";
            }
        },
        validateChannel () {
            console.log(this.selectedChannel, 'selectedChannel??');
            if (this.selectedChannel === '') {
                this.errors.channel = "Сувгаа сонгоно уу";
            } else {
                this.errors.channel = "";
            }
        },
        handleSizeChange (value) {
            this.pagination = { pageNumber: 1, pageSize: value }
            this.getOrderFilter()

        },
        handleCurrentChange (value) {
            this.pagination = { ...this.pagination, pageNumber: value }
            this.getOrderFilter()
        },
        handleClose () {
            this.modalOpen = false;
            this.isLoading = false;
            this.form = {
                phone: '',
                reason: '',
            }
        },
        handleShow(order_uuid) {
            const body = {
                order_uuid
            }
            managerServices.getOrderPayment(body).then(response => {
                if (response.success) {
                    this.order = response.result.order,
                    this.invoice = response.result.invoice

                } else {
                    this.$notify({
                        title: 'Алдаа гарлаа',
                        message: response.message,
                        type: 'error'
                   })
                }
            })
            this.modalOpen = true;
        },
        getOrderFilter () {
            this.validateOrderNumber()
            this.validatePhone()
            this.validateChannel()
            if (this.errors.order_number || this.errors.phone || this.errors.channel) {
                alert(`${this.errors.order_number} ${this.errors.phone} ${this.errors.channel}` );
            } else {
                this.isLoading = true;
                const body = {
                    ...this.pagination,
                    ...(this.phone ? { phone: this.phone } : {}),
                    ...(this.order_number ? { order_number: this.order_number } : {}),
                    ...(this.selectedChannel ? { channel: this.selectedChannel } : {}),
                    pageLen: this.pagination.pageSize,
                }
                try {
                    managerServices.getOrderFilter(body).then(response => {
                        this.isLoading = false;
                        if (response.success) {
                            this.list = response.result.data
                        } else {
                            this.$notify({
                                title: 'Алдаа гарлаа',
                                message: response.message,
                                type: 'error'
                            })
                        }
                    })
                } catch (error) {
                    this.isLoading = false;
                }
            }
        },
        create (data) {
            this.isLoading = true;
            managerServices.createBlacklist(data).then(response => {
                this.handleClose()
                if (response.success) {
                    this.$notify({
                        title: 'Амжилттай.',
                        message: 'Жагсаалтанд нэмэгдлээ.',
                        type: 'success'
                    })
                    this.getPaymentList()
                } else {
                    this.$notify({
                        title: 'Алдаа гарлаа',
                        message: response.message,
                        type: 'warning'
                    })
                }

            })
        },
        submitForm (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.create(this.form)
                }
            })
        },
        handleSearch () {
            console.log('here');
            this.getPaymentList()
        }
    }
}
</script>

<style scoped lang="scss">
.container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .header {
        display: flex;
        justify-content: space-between;

        .search {
            display: flex;

        }
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .pagination {
            float: right;
            text-align: right;
        }

       
    }
 

    .form-wrap{
        display: flex;
        gap: 10px;
        justify-content:flex-start;
        padding:5px 0;

        label {
            text-align: right;
        }
        
        p {
            font-weight: 500;
        }
    }

  
}
</style>
//hasComp